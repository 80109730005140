import { Link, useSearchParams} from 'react-router-dom';
import { PlusIcon, SortAscendingIcon } from '@heroicons/react/outline'
import AppSettings from '../app.settings.json'
import axios from 'axios'
import React, { useState, Fragment } from 'react'
import { Dialog, Transition, Menu } from '@headlessui/react';
import LoadingComponent from '../components/LoadingComponent'
import Pagination from '../components/Pagination';
import {Helmet} from 'react-helmet'
import moment from 'moment'
import 'moment/locale/fa';
import getCookie from "./getCookie"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Authors() {
    const [search, setSearch] = useState('');
    const [authors, setAuthors] = useState([])
    const [sort, setSort] = useState(4)
    const [isLoaded, setIsLoaded] = useState(false)
    const params = new URLSearchParams(window.location.search);
    const [totalAuthors, setTotalAuthors] = useState()
    const [totalPages, setTotalPages] = useState()
    const [perPage, setPerPage] = useState(10)
    const [page, setPage] = useState(params.get('page'))
    const [Delete, setDelete] = useState(false)
    const [currentId, setCurrentId] = useState(0);
    const auth = getCookie("refresh_token")

    function FetchData(per_page,_page){
	if(per_page==undefined){
	    per_page=perPage
	}
	if(_page==undefined){
	    _page=page
	}
	if(_page === null){
      		setPage(1)
		_page=1
    	}
        axios.get(`${AppSettings.APIserver}/author?page=${_page}&per_page=${per_page}`).then((response) => {
	    if(response.data.body.length==0){
		    setAuthors([])
		    setTotalPages(1)
		    setTotalAuthors(0)
		    setIsLoaded(true)
	    }else{
		    setTotalAuthors(response.data.body.total_authors)
		    setTotalPages(Math.ceil(parseInt(response.data.body.total_authors)/parseInt(per_page)))
		    response.data.body.authors.forEach(item => {
				let ago_date = moment(parseInt(item.date_of_started))
				ago_date.locale('fa')
				item.date_of_started = ago_date.fromNow()

				if(item.date_of_died == ""){
					item.date_of_died = "------------"
				}
		    });
		    setIsLoaded(true)
		    setAuthors(response.data.body.authors)
	}
        }).catch((error) => {
	    setIsLoaded(true)
	    console.log(error);
	});
    }
    React.useEffect(() => {
	FetchData()
    }, [])

    function handleSearch(keyword) {
		if(keyword!=""){
	    axios.post(`${AppSettings.APIserver}/search`,{
                'type': "author",
                'keyword': keyword,
                'authors': [],
                'categories': [],
                'order_by': sort
            }).then((response) => {

				response.data.author_data.forEach(item => {
					var timestamp = item.date_of_started
					var date = new Date(parseInt(timestamp));
					date = date.getDate() +
						"/" + (date.getMonth() + 1) +
						"/" + date.getFullYear();
					item.date_of_started = date;
				});

				response.data.author_data.forEach(item => {
					if(item.date_of_died == ""){
						item.date_of_died = "------------"
					}
				});
				setIsLoaded(true)
				setAuthors(response.data.author_data)
            }).catch((e) => {
                console.log(e)
            })
		}else{
			FetchData()
		}
    }
    function handelSort(sortBy) {
        setSort(sortBy)
        if (sortBy === 1) {
            let obj = authors
            obj = obj.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                } else {
                    return 1;
                }
            })
            setAuthors(obj)
        }
        else if (sortBy === 2) {
            let obj = authors
            obj = obj.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return 1;
                } else {
                    return -1;
                }
            })
            setAuthors(obj)
        }else if (sortBy === 3) {
            let obj = authors
            obj = obj.sort(function (a, b) {
                if (a.number_of_books < b.number_of_books) {
                    return 1;
                } else {
                    return -1;
                }
            })
            setAuthors(obj)
        }

    }

    function handleDelete(id) {
        setDelete(false)
        
        
        const config = {
          headers: { Authorization: `Bearer ${auth}` }
        };
        axios.delete(
          `${AppSettings.APIserver}/author/${id}`,
          config
        ).then(res => {
            if (res.data.status === "author_has_book") {
                alert("اگر نویسنده کتابی داشته باشد، نمی‌توانید این نویسنده را حذف کنید")
            } else {
                const res = authors.filter(book => book.id !== id)
                setAuthors(res)
            }
        })
    }

    return (
	<>
		<Helmet>
			<title>نویسنده ها</title>
		</Helmet>
        <Transition.Root show={Delete} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setDelete}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        شما مطمئن هستید که می خواهید این کتاب را حذف کنید؟
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={() => handleDelete(currentId)}
                  >
                    حذف
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setDelete(false)}
                  >
                    انصراف
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>


        <div className="flex flex-col p-4">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="px-1 py-2 border border-gray-200">
                        <div className='flex justify-between'>
                            <div className='w-60 inline-flex justify-evenly pt-4'>
                                <Link to="/add-author" className='w-5 h-5 cursor-pointer inline align-middle' ><PlusIcon /></Link>
                                <Menu as="div" className="relative inline-block text-right">
                                    <div>
                                        <Menu.Button>
                                            <span className="sr-only">Open options</span>
                                            <SortAscendingIcon className="h-6 w-6" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm'
                                                            )}
                                                            onClick={() => { handelSort(1) }}
                                                        >
                                                            نام(ا-ی)
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm'
                                                            )}
                                                            onClick={() => { handelSort(2) }}
                                                        >
                                                            نام(ی-ا)

                                                        </a>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            onClick={() => { handelSort(3) }}
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            تعداد کتاب ها
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                            <input
                                placeholder="جستجو ... "
                                className="inline p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-1/2 sm:text-sm border-gray-300 rounded-md"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                    handleSearch(e.target.value)
                                }}
                            />
                        </div>
                    </div>
	    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg w-full">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        نام
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        تعداد کتاب ها
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
	    				تاریخ ایجاد
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        تاریخ وفات
                                    </th>
                                    <th scope="col" className="relative px-6 py-3">
                                        <span className="sr-only">ویرایش</span>
                                        

                                    </th>
                                </tr>
                            </thead>
	    		     
                            <tbody className="bg-white divide-y divide-gray-200">
                               {authors.map((author) => (
                                    <tr key={author.id} className="cursor-pointer hover:bg-gray-100" >
                                        <td className="px-6 py-4 whitespace-nowrap" >
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0 h-10 w-10">
                                                    <img className="h-10 w-10 rounded-full" src={AppSettings.APIserver + author.image_url} alt="" />
                                                </div>
                                                <div className="mr-4">
                                                    <Link className="text-sm font-medium text-gray-900" to={`/author/${author.id}`}>{author.name}</Link>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">{author.number_of_books}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{author.date_of_started}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{author.date_of_died}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <Link to={`/edit-author/${author.id}`} className="text-indigo-600 hover:text-indigo-900">
                                                ویرایش
                                            </Link>
                                            <button className="text-red-600 mr-3 border border-red-200 px-4 rounded" 
                                            onClick={() => {
                                                setCurrentId(author.id);
                                                setDelete(true);
                                            }}>
                                                حذف
                                        </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
	    		{isLoaded == false ? <LoadingComponent /> : null}
                    </div>
                </div>
            </div>
        <div className="my-10">
	<Pagination 
	    total={totalAuthors} 
	    totalPages={totalPages} 
	    per_page={perPage} 
	    set_per_page={(a,b)=>{setPerPage(a);setPage(b);FetchData(a,b)}}
	    set_page={(a,b)=>{setPerPage(a);setPage(b);FetchData(a,b)}} />
	  </div>
	</div>
	</>
)
}
