import { useEffect, useState} from 'react';

function SearchDropDown(props){

	const [show, setShow] = useState(false);
	const [isSelected, setIsSelected] = useState(false);
	const [selected, setSelected] = useState();
	const [isSet, setIsSet] = useState(true);
	const DisplayDropDown=()=>{
		setIsSet(false)
		setIsSelected(false);
                setShow(!show);
	}
	const handelSearch=(e)=>{
		if(e.target.value !== ""){
			props.search(e.target.value);
		}
	}
	const handelSelected=(name,id)=>{
		setSelected(name);
		setIsSelected(true);
		setShow(false);
		props.setSelected(id)
	}
	return (
	<div className="w-full">
        	<div className="Buttons">
			<label htmlFor="BooknName" className="block text-sm font-medium text-gray-700">
                * نویسنده 
        	</label>
		{(props.authorName === undefined||props.authorName===""||isSet===false)?null:<div className="z-10 w-full mt-1 text-base list-none bg-white rounded divide-y divide-gray-100 shadow" onClick={DisplayDropDown} >
						<a href="#" className="block px-4 text-sm text-gray-700 py-3"> {props.authorName} </a>
				</div>	}

			<input
				onChange={e=>{handelSearch(e)}}
				onClick={DisplayDropDown} 
				placeholder="جستجو .."
				className={isSelected?"hidden":"mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "} />
				<div className={isSelected?"z-10 w-full mt-1 text-base list-none bg-white rounded divide-y divide-gray-100 shadow ":"hidden"} onClick={DisplayDropDown} >
					<a href="#" className="block px-4 text-sm text-gray-700 py-3"> {selected} </a>
				</div>	
		</div>
		{
			show && <div className={"z-10 w-full text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700"}>
               		<ul className="py-1" aria-labelledby="dropdownButton">
				{props.Authors.map((author) => { 
					return (
					<li key={author.id}>
						<a onClick={()=>{handelSelected(author.name,author.id)}}
						 className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">{author.name}
						</a>
					</li>
				)})}
			</ul>
		</div>
		}
 		
	</div>);
}

export default SearchDropDown;


