import axios from "axios"
import AppSettings from "../app.settings.json"
import React, { useState, Fragment } from "react"
import { useNavigate } from "react-router-dom"
import getCookie from "./getCookie"
import {Helmet} from "react-helmet"

export default function AddCategory() {
  const [name, setName] = useState()
  const [imageUrl, setImageUrl] = useState()
  const auth = getCookie("refresh_token")
  const navigate = useNavigate()
  

  function handelSubmit() {
    
    if (name === "" || name === undefined) {
      alert("لطفا عکس و نام دسته بندی را وارد کنید")
    } else {
      const config = {
        headers: { Authorization: `Bearer ${auth}` }
      };
      const data = new FormData()
      data.append('name', name)
      data.append('date_of_started', Date.now())
      if(imageUrl !== undefined){
        data.append('image_url', imageUrl)
      }
      axios.post(
        `${AppSettings.APIserver}/category`,
        data,
        config
      ).then((res => {
        navigate("/category",{replace:true})

      })).catch(error => {
        console.log(error)
      })
    }
  }
	function handelKeyPress(event) {
    if (event.key === "Enter") {
      handelSubmit()
    }
  }

  

  return (
    <>
	<Helmet>
			<title>افزودن دسته بندی</title>
	</Helmet>
  

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div action="#" method="#">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <h2>اضافه کردن دسته بند</h2>
                  <div className="px-6">
                    <label className="block text-sm font-medium text-gray-700 py-3">عکس دسته بندی</label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                          >
                            <span className="text-center">:عکس انتخاب شده باید</span>
                            <input 
                              id="file-upload" 
                              name="file-upload"
                              type="file" 
                              onChange={(e) => {
                                setImageUrl(e.target.files[0])
                              }} 
                              accept="image/jpeg, image/png, image/jpg"
                            />
                          </label>
                        </div>
                        <p className="text-xs text-gray-500">jpg , jpeg ,PNG</p>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 bg-white sm:px-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-4">
                        <label htmlFor="AuthorName" className="block text-sm font-medium text-gray-700 py-3">
	  			نام دسته بندی 
                        </label>
                        <input
							onKeyPress={handelKeyPress} 
                          onChange={(e => {
                            setName(e.target.value)
                          })}
                          type="text"
                          name="AuthorName"
                          id="AuthorName"
                          autoComplete="name"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-6">
                    <div className="py-4 mb-4">
                      <button
                        onClick={handelSubmit}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 float-right"
                      >
                        ذخیره
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
