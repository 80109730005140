import Books from './components/Books';
import Header from './components/Header';
import Login from './pages/Login'
import { HashRouter, Routes, Route } from "react-router-dom";
import React from 'react'
import AddBookPage from './pages/AddBookPage';
import Users from './pages/Users'
import Slider from './pages/Slider'
import Category from './pages/categorys'
import Authors from './pages/Authors'
import EditBook from './pages/EditBook'
import BookDetails from './pages/BookDetails'
import AddCategory from './pages/AddCategory'
import EditCategory from './pages/EditCategory'
import CategoryDetails from './pages/CategoryDetails'
import AddAuthor from './pages/AddAuthor'
import EditAuthor from './pages/EditAuthor'
import AuthorDetails from './pages/AuthorDetails'
import AddUser from './pages/AddUser'
import EditUser from './pages/EditUser'
import PageNotFound from './pages/PageNotFound'
function App() {
  return (
	<HashRouter>
                <Routes>
			<Route exact path='/login' element={<Login />} />
			<Route exact path='/add-book' element={
            			<Header current="Book">
              				<AddBookPage />
            			</Header> 
			} />
			<Route exact path='/' element={
           			<Header current="Book">
              				<Books />
            			</Header> 
			} />
      			<Route exact path='/users' element={
            			<Header current="User">
              				<Users />
            			</Header> 
			} />
			<Route exact path='/slider' element={
            			<Header current="Slider">
              				<Slider />
            			</Header>
			} />
			<Route exact path='/category' element={
            			<Header current="Category">
              				<Category />
            			</Header>
          		} />
        		<Route exact path='/authors' element={
            			<Header current="Authors">
              				<Authors />
            			</Header>
          		} />
        		<Route exact path='/edit-book/:id' element={
            			<Header>
              				<EditBook />
            			</Header>
          		} />
 			<Route exact path='/book/:id' element={
            			<Header>
              				<BookDetails />
            			</Header>
          		} />
			<Route exact path='/category/:id' element={
            			<Header>
              				<CategoryDetails />
            			</Header>
			} />
			<Route exact path='/add-category' element={
            			<Header>
              				<AddCategory />
            			</Header>
          		} />
			<Route exact path='/edit-category/:id' element={
            			<Header>
              				<EditCategory />
            			</Header>
          		} />
        		<Route exact path='/add-author' element={
            			<Header>
              				<AddAuthor />
            			</Header>
          		} />
  			<Route exact path='/author/:id' element={
            			<Header>
              				<AuthorDetails />
            			</Header>
          		} />
			<Route exact path='/add-user' element={
            			<Header>
              				<AddUser />
            			</Header>
          		} />
			<Route exact path='/edit-author/:id' element={
            			<Header>
              				<EditAuthor />
            			</Header>
        		} />
			<Route exact path='/edit-user/:id' element={
				<Header>
					<EditUser />
				</Header>
			} />
	  		<Route path='*' element={<PageNotFound />} />
		</Routes>
	</HashRouter>
  );
}

export default App; 
