import {Fragment} from 'react'
import { Link } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/outline'
import AppSettings from '../app.settings.json'
import axios from 'axios'
import React, { useState } from 'react'
import LoadingComponent from '../components/LoadingComponent'
import {Helmet} from "react-helmet";
import moment from "moment";
import 'moment/locale/fa';
import { Dialog, Transition } from '@headlessui/react';
import getCookie from '../pages/getCookie';

export default function Category() {
    const [search, setSearch] = useState('')
    const [categorys, setCategorys] = useState([]);
    const [lastCategory, setLastCategory] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [Delete, setDelete] = useState(false)
    const [currentId, setCurrentId] = useState(0);
    const auth = getCookie("refresh_token")

    React.useEffect(() => {
        axios.get(`${AppSettings.APIserver}/category`).then((response) => {
             response.data.body.forEach(item => {
				let ago_date = moment(parseInt(item.date_of_started))
				ago_date.locale('fa')
				item.date_of_started = ago_date.fromNow()
            });
	   setIsLoaded(true)
	   setCategorys(response.data.body)
           //This is for Search I know this is not the best way to do it but it works just fine
	   setLastCategory(response.data.body)
        });
    }, []);
    
    function handelSearch(word) {
        if (word !== "") {
            setCategorys(lastCategory)
            let isFound =false;
            lastCategory.forEach(i => {
                if (i.name === word) {
                    setCategorys([i])
                    isFound = true
                }
            })
            if (isFound === false) {
                setCategorys([])
            }
        } else {
            setCategorys(lastCategory)
        }
    }

    function handleDelete(id) {
        console.log(id)
        setDelete(false)
        
        const config = {
          headers: { Authorization: `Bearer ${auth}` }
        };
        axios.delete(
          `${AppSettings.APIserver}/category/${id}`,
          config
        ).then(res => {
            if (res.data.status === "cant_delete") {
                alert("Cant delete this category")
            } else {
                const res = categorys.filter(book => book.id !== id)
                setCategorys(res)
            }
        })
    }

    return (
		<>
		<Helmet>
			<title>دسته بندی ها</title>
		</Helmet>
        <div className="flex flex-col p-4">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="px-1 py-2 border border-gray-200">
                        <div className='flex justify-between'>
                            <div className='w-32 inline-flex justify-evenly pt-4'>
                                <Link to="/add-category" className='w-5 h-5 cursor-pointer inline align-middle' ><PlusIcon /></Link>
                            </div>
                            <input
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                    handelSearch(e.target.value)
                                }}
                                placeholder="جستجو ..."
                                className="inline p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-1/2 sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>
                    </div>
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        نام
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                       تعداد کتاب ها 
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        تاریخ ایجاد
                                    </th>
                                    <th scope="col" className="relative px-6 py-3">
                                        <span className="sr-only">ویرایش</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {categorys.map((category) => (
                                    <tr key={category.id} className="cursor-pointer hover:bg-gray-100" >
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0 h-10 w-10">
													<img 
                                                        className="h-10 w-10 rounded-full" 
                                                        src={AppSettings.APIserver + category.image_url} 
                                                        alt="" 
                                                    />
                                                </div>
                                                <div className="mr-4">
                                                    <Link className="text-sm font-medium text-gray-900" to={`/category/${category.id}`}>{category.name}</Link>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">{category.number_of_books}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{category.date_of_started}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <Link to={`/edit-category/${category.id}`} className="text-indigo-600 hover:text-indigo-900">
                                                ویرایش
                                            </Link>
                                            <button className="text-red-600 mr-3 border border-red-200 px-4 rounded" 
                                            onClick={() => {
                                                setCurrentId(category.id);
                                                setDelete(true);
                                            }}>
                                                حذف
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Transition.Root show={Delete} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setDelete}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div>
                        <div className="mt-3 text-center sm:mt-5">
                            <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                شما مطمئن هستید که می خواهید این کتاب را حذف کنید؟
                            </p>
                            </div>
                        </div>
                        </div>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                            onClick={() => handleDelete(currentId)}
                        >

                            حذف
                        </button>
                        <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                            onClick={() => setDelete(false)}
                        >
                            انصراف
                        </button>
                        </div>
                    </div>
                    </Transition.Child>
                </div>
                </Dialog>
            </Transition.Root>

	    		{isLoaded === false ? <LoadingComponent /> : null}
                    </div>
                </div>
            </div>
        </div>
		</>
    )
}
