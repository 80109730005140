import axios from "axios"
import AppSettings from "../app.settings.json"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import getCookie from "./getCookie"
import {Helmet} from "react-helmet"

export default function AddAuthor() {
  const [name, setName] = useState()
  const [datedied, setDate] = useState()
  const [imageUrl, setImageUrl] = useState()
  const [description, setDescription] = useState("")
  const auth = getCookie("refresh_token")
  const navigate = useNavigate()

  function handelKeyPress(event) {
    if (event.key === "Enter") {
      handelSubmit()
    }
  }

  function handelSubmit() {
    if (name === "" || name === undefined) {
      alert("Fill The Inputs")
    } else {
      const config = {
        headers: { Authorization: `Bearer ${auth}` }
      };
      const data = new FormData()
      data.append('name', name)
	  data.append('description', description)
      data.append('date_of_started', Date.now())
	  if(datedied === "" || datedied === null || datedied === undefined){
		  data.append('date_of_died', "")
	  }else{
		  data.append('date_of_died', datedied)
	  }
	  if(imageUrl !== undefined){
		  data.append('image_url', imageUrl)
	  }else{
		  data.append('image_url', "")
	  }
    
      axios.post(
        `${AppSettings.APIserver}/author`,
        data,
        config
      ).then((res => {
        navigate("/authors",{replace:true})

      })).catch(error => {
        console.log(error)
      })
    }
  }
  return (
    <>
	  <Helmet>
	  		<title>افزودن نویسنده</title>
	  </Helmet>

    
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div action="#" method="#">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <h2>اضافه کردن نویسنده</h2>
                  <div className="px-6">
                    <label className="block text-sm font-medium text-gray-700">عکس نویسنده</label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                          >
                            <input id="file-upload" name="file-upload" type="file" 
                             onChange={(e) => {
                              setImageUrl(e.target.files[0])
                            }}
                            accept="image/jpeg, image/png, image/jpg" 
                            />
                          </label>
                        </div>
                        <p className="text-xs text-gray-500">jpg , jpeg , PNG</p>
                      </div>
                    </div>

                  </div>
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-4">
                        <label htmlFor="AuthorName" className="block text-sm font-medium text-gray-700">
                          نام نویسنده
                        </label>
                        <input
                          onChange={(e => {
                            setName(e.target.value)
                          })}
                          type="text"
                          name="AuthorName"
                          id="AuthorName"
                          autoComplete="name"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-4">
                        <label htmlFor="datedied" className="block text-sm font-medium text-gray-700">
                          درباره نویسنده 
                        </label>
                        <textarea
                          onChange={(e => {
                            setDescription(e.target.value)
                          })}
                          name="description"
                          id="description"
                          className="mt-1 border p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        >
						</textarea>
                      </div>
                      <div className="col-span-6 sm:col-span-4">
                        <label htmlFor="datedied" className="block text-sm font-medium text-gray-700">
                          تاریخ وفات
                        </label>
                        <input
                          onChange={(e => {
                            setDate(e.target.value)
                          })}
                          name="datedied"
	  				  	  onKeyPress={handelKeyPress}
                          id="datedied"
                          autoComplete="date"
                          className="mt-1 border p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-6">
                    <div className="py-4 mb-4">
                      <button
                        onClick={handelSubmit}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 float-right"
                      >
                        ذخیره
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
