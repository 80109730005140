import { PaperClipIconm,PaperClipIcon} from '@heroicons/react/solid'
import { useParams } from "react-router-dom"
import axios from 'axios'
import React, { useState } from 'react'
import AppSettings from '../app.settings.json'
import {Helmet} from 'react-helmet'

export default function BookDetails() {
  const { id } = useParams()
  const [BookDetail, setBookDetail] = useState([])
  const [AuthorName, setAuthorName] = useState("")
  const [CategoryName, setCategoryName] = useState("")
  React.useEffect(() => {
    axios.get(`${AppSettings.APIserver}/book/${id}`).then((response) => {
	// to conver the date of added time stamp to real date
	var timestamp = response.data.body.date_of_added
	var date = new Date(parseInt(timestamp));
	date = date.getDate() +
	"/" + (date.getMonth() + 1) +
	"/" + date.getFullYear();
	response.data.body.date_of_added = date

	axios.get(`${AppSettings.APIserver}/author/${response.data.body.author_id}`).then((response) => {
	    setAuthorName(response.data.body.name)
	});
        axios.get(`${AppSettings.APIserver}/category/${response.data.body.category_id}`).then((response) => {
	    setCategoryName(response.data.body.name)
        });

      	setBookDetail(response.data.body)
    });
    
  }, []);
  return (
	<>
	<Helmet>
		<title>{BookDetail.title}</title>
	</Helmet>
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">توضیحات کتاب</h3>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 w-full justify-center pr-4" style={{display:'flex'}}>
            <img
              className="inline-block h-40 w-28 rounded-md cursor-pointer" src={AppSettings.APIserver + BookDetail.image_url}  />
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">نام کتاب</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{BookDetail.title}</dd>
          </div>
           <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">مترجم</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{BookDetail.translator}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">ناشر</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{BookDetail.publisher}</dd>
          </div>
	  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">نویسنده</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{AuthorName}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">دسته بندی</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{CategoryName}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">سال نشر</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{BookDetail.publisher_year}</dd>
          </div>
 	  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">نسخه</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{BookDetail.type_version}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">تاریخ ایجاد</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{BookDetail.date_of_added}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">درباره کتاب</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {BookDetail.about_book}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">منبع</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {BookDetail.infinitive}
            </dd>
          </div>
          <div className='px-6 py-4'>
            <h3 className="text-lg leading-6 font-medium text-gray-900">انالیز</h3>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">بازدید</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">{BookDetail.most_viewed}</dd>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">دانلود</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">{BookDetail.download_count}</dd>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">(بازدید و دانلود)ویژه</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">{BookDetail.featured_book}</dd>
              </div>
            </dl>
          </div>
        </dl>
      </div>
    </div>
	</>
  )
}
