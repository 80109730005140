import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'
export default function Pagination(props) {
  const params = new URLSearchParams(window.location.search);
  const [Total, setTotal] = useState(props.total)
  const [totalPages, setTotalPages] =  useState(props.totalPages)
  const [pageNumber, setPageNumber] = useState( params.get('page'))
  const [perPageInput, setPerPageInput] = useState(props.per_page)
  function onCreated(){
	if(pageNumber === null||pageNumber==""){
	   setPageNumber(1)
	}
	let number = pageNumber 
	if(number==2){
		number = number - 1
	}else if(number>2){
		number = number - 2
	}

  }

  React.useEffect(() => {
	onCreated()
  }, []);

  function handelPerPage(e){
	  setPerPageInput(e.target.value)
	  props.set_per_page(e.target.value,pageNumber)
  }

  function NextPage(){
	  setPageNumber(parseInt(pageNumber)+1)
          onCreated()
	  props.set_page(perPageInput,parseInt(pageNumber)+1)
  }

  function PerviousPage(){
	  setPageNumber(parseInt(pageNumber)-1)
          onCreated()
	  props.set_page(perPageInput,parseInt(pageNumber)-1)
  }
  function SetPage(num){
	  if(num ==1){
		  setPageNumber(1)
		  onCreated()
		  props.set_page(perPageInput,1)
	  }else{
		  setPageNumber(parseInt(pageNumber)+num)
		  onCreated()
		  props.set_page(perPageInput,parseInt(pageNumber)+num)
	  }
  }

  return (
    <div className="bg-white px-4 py-3 items-center border-t border-gray-200 sm:px-6" style={{direction:"ltr"}}>
      <div className="w-full">
        <div className="flex justify-between">
	  <div>
		  <p className="text-sm leading-5 text-gray-500">
			تعداد نمایش
		  </p>
		  <select onChange={(e)=>{handelPerPage(e)}} >
			<option value={props.per_page} >{props.per_page}</option>
			<option value="5">5</option>
			<option value="10">10</option>
			<option value="20">20</option>
			<option value="50">50</option>
			<option value="100">100</option>
			<option value="200">200</option>
			<option value={props.total}>{props.total} همه</option>

		  </select>
	  </div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px " aria-label="Pagination">
            <a
	      onClick={PerviousPage}
	      id="perviousPage"
              className={pageNumber<=1?"hidden":"relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>

   	   	<a
	  		  onClick={e=>{SetPage(1)}}
              href="#"
              aria-current="page"
              className={pageNumber<=1?"hidden":"border-gray-500 text-gray-500 bg-white-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium "}
            >
			<p>
				1
			</p>
        </a>
   	   	<a
              href="#"
              aria-current="page"
              className="border-indigo-500 text-indigo-500 bg-indigo-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium "
            >
			<p>
				{pageNumber}
			</p>
        </a>
   	   	<a
	  		  onClick={e=>{SetPage(props.totalPages)}}
              aria-current="page"
              className={pageNumber>=props.totalPages?"hidden":"border-gray-500 text-gray-500 bg-white-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium "}
            >
			<p>
	  			{props.totalPages}
			</p>
        </a>
          
            <a
	      id="nextPage"
              onClick={NextPage}
              className={pageNumber>=props.totalPages?"hidden":"inline-flex relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
	<div>
	  <h1 className="inline-block text-lg">{props.total} : تعداد کل </h1>
	</div>
        </div>
      </div>
    </div>
  )
}

