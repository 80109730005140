import axios from "axios"
import AppSettings from "../app.settings.json"
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import getCookie from "./getCookie"
import {Helmet} from "react-helmet"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function EditBook() {
    const { id } = useParams();
    const [data, setData] = useState([])
    const [name, setName] = useState('')
    const [datedied, setDateDied] = useState()
    const [imageUrl, setImageUrl] = useState()
	const [description, setDescription] = useState()

    const auth = getCookie("refresh_token")
    const navigate = useNavigate()
    React.useEffect(() => {
        axios.get(`${AppSettings.APIserver}/author/${id}`).then((response) => {
            setData(response.data.body)
            setName(response.data.body.name)
            setDateDied(response.data.body.date_of_died)
            setImageUrl(response.data.body.image_url)
			setDescription(response.data.body.description)
        });

    }, []);
    function handelSubmit() {
        const config = {
            headers: { "Authorization": `Bearer ${auth}` }
        };
	//convert date_of_died to timestamp
		console.log(data)
	var form_data = new FormData()
        form_data.append('name', name)
		form_data.append('description', description)
        form_data.append('date_of_started', data.date_of_started)
        form_data.append('date_of_died', datedied)
        form_data.append('image_url', imageUrl)

        axios.put(
            `${AppSettings.APIserver}/author/${id}`,
            form_data,
            config
        ).then((res => {
            navigate("/authors", { replace: true })

        })).catch(error => {
            console.log(error)
        })
    }
    return (
        <>
			<Helmet>
				<title>ویرایش نویسنده</title>
			</Helmet>
            <div>
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <div>
                            <div className="shadow sm:rounded-md sm:overflow-hidden">
                                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                    <h2>ویرایش نویسنده</h2>

                                    <div className="px-4 py-5 bg-white sm:p-6">
                                        <div className="grid grid-cols-6 gap-6 pt-4 pb-5">
                                            <div className="sm:col-span-6">
                                                <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                                                    تصویر
                                                </label>
                                                <div className="mt-1 flex items-center">
                                                    <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                                                        <img className="h-full w-full" src={AppSettings.APIserver + imageUrl} />
                                                    </span>
                                                    <input
                                                        type="file"
                                                        className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onChange={(e) => {
	setImageUrl(e.target.files[0])
}} />

                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-4">
                                                <label htmlFor="AuthorName" className="block text-sm font-medium text-gray-700">
                                                    نام نویسنده
                                                </label>
                                                <input
                                                    onChange={(e => {
                                                        setName(e.target.value)
                                                    })}
                                                    value={name}
                                                    type="text"
                                                    name="AuthorName"
                                                    id="AuthorName"
                                                    autoComplete="name"
                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-4">
                                                <label htmlFor="AuthorName" className="block text-sm font-medium text-gray-700">
                                                  درباره نویسنده
                                                </label>
                                                <textarea
                                                    onChange={(e => {
                                                        setDescription(e.target.value)
                                                    })}
                                                    value={description}
                                                    type="text"
                                                    name="description"
                                                    id="description"
                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                                                ></textarea>
                                            </div>
                                            <div className="col-span-6 sm:col-span-4">
                                                <label htmlFor="datedied" className="block text-sm font-medium text-gray-700">
                                                     تاریخ وفات
                                                </label>
                                                <input
                                                    onChange={(e => {
                                                        setDateDied(e.target.value)
                                                    })}
                                                    value={datedied}
                                                    name="datedied"
                                                    id="datedied"
                                                    autoComplete="date"
                                                    className="mt-1 border p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>
                                        </div>


                                    </div>
                                    <div className="px-6">
                                        <div className="py-4 mb-4">
                                            <button
                                                onClick={handelSubmit}
                                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 float-right"
                                            >
                                                ذخیره
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
