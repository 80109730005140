import axios from "axios"
import AppSettings from "../app.settings.json"
import React, { useState } from "react"
import { Link, useNavigate,useParams } from "react-router-dom"
import getCookie from "./getCookie"
import {Helmet} from "react-helmet"

export default function EditUser() {
    const [name, setName] = useState()
    const [role, setRole] = useState(0)
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const auth = getCookie("refresh_token")
    const navigate = useNavigate()
    const { id } = useParams();

    React.useEffect(() => {
        axios.get(`${AppSettings.APIserver}/user/${id}`).then((res) => {
            setName(res.data.body.name)
            setRole(res.data.body.is_admin)
        }).catch((e)=>{
            console.log(e)
        })
    }, []);
    function handelSubmit() {
        if (password === confirmPassword) {
            const config = {
                headers: { Authorization: `Bearer ${auth}` }
            };
            let data = {
                name: name,
                password: password,
                is_admin: role
            }
            axios.put(
                `${AppSettings.APIserver}/user/${id}`,
                data,
                config
            ).then(
                navigate("/users", { replace: true })
            ).catch(error => {
                console.log(error)
            })

        } else {
            alert("password and confirm password need to be same!")
            setPassword("")
            setConfirmPassword("")
        }
    }
    return (
        <div>
			<Helmet>
				<title>ویرایش کاربر</title>
			</Helmet>
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="mt-5 md:mt-0 md:col-span-2">
                    <div>
                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                            <div className="px-4 py-2 bg-white space-y-6 sm:p-6">
                                <div className="px-6">
                                    <h2>ویرایش کاربر</h2>
                                </div>
                                <div className="px-4 py-2 bg-white sm:p-6">
                                    <div className="grid gap-6 ">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                                نام کاربری
                                            </label>
                                            <input
                                                value={name}
                                                onChange={(e) => { setName(e.target.value) }}
                                                placeholder="نام کاربری"
                                                type="username"
                                                name="username"
                                                id="username"
                                                autoComplete="name"
                                                required
                                                className="mt-1 focus:ring-indigo-500  w-3/4 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                                            />
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                                                نقش کاربری
                                            </label>
                                            <select
                                                value={role}
                                                onChange={(e) => { setRole(e.target.value) }}
                                                placeholder="role"
                                                type="role"
                                                name="role"
                                                required
                                                id="role"
                                                className="mt-1 focus:ring-indigo-500 w-3/4 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2"
                                            >
                                                <option value="0">کاربر</option>
                                                <option value="1">مدیر</option>
                                            </select>
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                رمز عبور جدید
                                            </label>
                                            <input
                                                value={password}
                                                onChange={(e) => { setPassword(e.target.value) }}
                                                placeholder="رمز عبور جدید"
                                                type="password"
                                                name="password"
                                                id="password"
                                                required
                                                autoComplete="password"
                                                className="mt-1 focus:ring-indigo-500 w-3/4 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                تکرار رمز عبور جدید
                                            </label>
                                            <input
                                                value={confirmPassword}
                                                onChange={(e) => { setConfirmPassword(e.target.value) }}
                                                placeholder="تکرار رمز عبور جدید"
                                                type="password"
                                                name="confirmpassword"
                                                id="confirmpassword"
                                                autoComplete="confirmpassword"
                                                required
                                                className="mt-1 focus:ring-indigo-500 w-3/4 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-5">
                                    <div className="flex justify-end">
                                        <Link
                                            to="/users"
                                            type="button"
                                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            لغو
                                        </Link>
                                        <button
                                            onClick={handelSubmit}
                                            className="mx-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            ذخیره
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
