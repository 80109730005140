import { PlusIcon } from "@heroicons/react/outline"
import { Link, useNavigate } from "react-router-dom";
import React, { useState, Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react';
import AppSettings from '../app.settings.json'
import axios from "axios";
import getCookie from '../pages/getCookie';
import LoadingComponent from '../components/LoadingComponent';
import {Helmet} from "react-helmet";

export default function Users() {
  const [users, setUsers] = useState([])
  const auth = getCookie("refresh_token")
  const is_admin = getCookie("is_admin")
  const [Delete, setDelete] = useState(false)
  const [currentId, setCurrentId] = useState(0);
  const navigate = useNavigate()
  const [isLoaded, setIsLoaded] = useState(false)
  React.useEffect(() => {
    if(is_admin==0){
      navigate('/')
    }
    axios.get(`${AppSettings.APIserver}/register`).then((res) => {
      setIsLoaded(true)
      setUsers(res.data.body)
    }).catch((e) => {
      console.log(e)
    })

  }, [])
  function handleDelete(id) {
    setDelete(false)
    const res = users.filter(user => user.id !== id)
    setUsers(res)
    const config = {
      headers: { Authorization: `Bearer ${auth}` }
    };
    axios.delete(
      `${AppSettings.APIserver}/user/${id}`,
      config
    )
  }
  return (
    <React.Fragment>
	  <Helmet>
		<title>کاربر ها</title>
	  </Helmet>
      <Transition.Root show={Delete} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setDelete}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        شما مطمئن هستید که می خواهید این کاربر را حذف کنید؟
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={() => handleDelete(currentId)}
                  >
                    حذف
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setDelete(false)}
                  >
                    انصراف
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="flex flex-col p-4">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="px-1 py-2 border border-gray-200">
              <div className='flex justify-between'>
                <div className='w-40 inline-flex justify-evenly pt-4'>
                  <a onClick={() => {
                          if (getCookie("is_admin") == 1) {
                            navigate('/add-user', { replace: true })
                          } else {
                            alert("شما دسترسی لازم برای این کار را ندارید")
                          }
                        }}className='w-5 h-5 cursor-pointer inline align-middle' ><PlusIcon /></a>
                  <div className='w-5 h-5 cursor-pointer inline align-middle table mb-2' />

                </div>
              </div>
            </div>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      نام
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      رمز عبور
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      نقش
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">ویرایش</span>
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">حذف</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, userIdx) => (
                    <tr key={user.id} className={userIdx % 2 === 0 ? 'bg-white hover:bg-gray-100' : 'bg-gray-50 hover:bg-gray-100' + 'cursor-pointer'}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">**********</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{(user.is_admin == 0) ? "کاربر" : "مدیر"}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <a className="cursor-pointer text-indigo-600 hover:text-indigo-900" onClick={() => {
                          if (getCookie("is_admin") == 1) {
                            navigate(`/edit-user/${user.id}`, { replace: true })
                          } else {
                            alert("شما دسترسی لازم برای این کار را ندارید")
                          }
                        }}
                        >
                          ویرایش
                        </a>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <a href="#" className="text-red-600 hover:text-red-900" onClick={() => {
                          if (getCookie("is_admin") == 1) {
                            setCurrentId(user.id);
                            setDelete(true);
                          } else {
                            alert("شما دسترسی لازم برای این کار را ندارید")
                          }
                        }}>
                          حذف
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
	      {isLoaded == false ? <LoadingComponent /> : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
