import React, { useEffect } from 'react';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Link, useNavigate } from 'react-router-dom';
import getCookie from '../pages/getCookie';
import {
  ViewBoardsIcon,
  BookOpenIcon,
  PhotographIcon,
  MenuIcon,
  UsersIcon,
  UserGroupIcon,
  XIcon,
  LogoutIcon,
} from '@heroicons/react/outline'

const username = getCookie("username")
const is_admin = getCookie("is_admin")

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header(props){

const [navigation,setNavigation] = useState([
  { name: ' کتاب ها ', href: '/', icon: BookOpenIcon, current:"Book"},
  { name: ' دسته بندی ها ', href: '/category', icon: ViewBoardsIcon, current:"Category" },
  { name: ' نویسنده ها ', href: '/authors', icon: UserGroupIcon, current:"Authors"},
  { name: ' کاربر ها ', href: '/users', icon: UsersIcon , current:"User"},
  { name: ' سلاید ها ', href: '/slider', icon: PhotographIcon, current:"Slider" },
])
  let navigate = useNavigate()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  React.useEffect(() => {
  if(is_admin==0){
	setNavigation(navi=>navi.filter(n=>n.current!="User"))
  }
    const refreshToken = getCookie("refresh_token")
    const accessToken = getCookie("access_token")
    if (accessToken === "" || accessToken === undefined || accessToken === null || refreshToken === "" || refreshToken === undefined || refreshToken === null) {
      navigate("/login", { replace: true });
    }
  }, []);
  function handleLogout() {
    document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "is_admin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    navigate("/login", { replace: true });
  }
  return (
    <>
      <div className="h-full flex">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 overflow-y-auto">
                <Link to="/" className="flex items-center flex-shrink-0 p-4">
	  	  <img
                    className="h-16 w-16"
                    src={process.env.PUBLIC_URL + 'images/logo.png'}
                    alt="logo"
                  />
	  </Link>
                  <nav aria-label="Sidebar" >
                    <div className="px-2 space-y-1">

                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                              'ml-4 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </nav>
                </div>
                <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                  <Link to="/" className="flex-shrink-0 ">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-9 w-9 rounded-full"
                          src={process.env.PUBLIC_URL + 'images/user_avatar.jpeg'}
                          alt="Avatar"
                        />
                      </div>
                      <div className="mr-3">
                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{username}</p>
                        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{is_admin == 0 ? 'کاربر' : 'مدیر'}</p>
                      </div>
                    </div>
                  </Link>
		  <div className="w-full pt-2">
	        	<LogoutIcon onClick={handleLogout} className="cursor-pointer h-6 w-6 text-red-400 float-left"/>
		  </div>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0 h-screen">
          <div className="flex flex-col w-48">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-gray-100">
              <div className="flex-1 flex flex-col overflow-y-auto">
                <Link to="/" className="flex justify-center flex-shrink-0 p-4">
                  <img
                    className="h-18 w-20"
                    src={process.env.PUBLIC_URL + 'images/logo.png'}
                    alt="logo"
                  />
                </Link>
                <nav className="flex-1" aria-label="Sidebar">
                  <div className="px-2 space-y-1">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          props.current == item.current
                            ? 'bg-gray-200 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            props.current == item.current
                              ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                            'ml-3 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </nav>
              </div>
              <div className="flex border-t border-gray-200 p-4">
                <Link to="/" className="w-full ">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-9 w-9 rounded-full"
                        src={process.env.PUBLIC_URL + 'images/user_avatar.jpeg'}
                        alt="Avatar"
                      />
                    </div>
                    <div className="mr-3">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{username}</p>
                      <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{is_admin == 0 ? 'کاربر' : 'مدیر'}</p>
                    </div>
                  </div>
                </Link>
		<div className="w-full pt-2">
	        	<LogoutIcon onClick={handleLogout} className="cursor-pointer h-6 w-6 text-red-400 float-left"/>
		</div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
          <div className="lg:hidden">
            <div className="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5">
              <div>
                <img
                        className="inline-block h-9 w-9 rounded-full"
                        src={process.env.PUBLIC_URL + 'images/user_avatar.jpeg'}
                        alt="Avatar"
                />
              </div>
              <div>
                <button
                  type="button"
                  className="-ml-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className="flex-1 relative z-0 flex overflow-hidden">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
              {/* Start main area*/}
              <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
                <div className="h-full p-4 mb-2 ">
                  {props.children}
                </div>
              </div>
              {/* End main area */}
            </main>
          </div>
          <div className="lg:hidden">
            <div className="h-full p-4 mb-2 ">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
