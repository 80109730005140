import { PlusIcon, CheckIcon} from '@heroicons/react/outline';
import {TrashIcon } from '@heroicons/react/solid';
import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import AppSettings from '../app.settings.json'
import LoadingComponent from '../components/LoadingComponent';
import getCookie from './getCookie';
import {Helmet} from 'react-helmet';

export default function SliderPage() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [slides, setSlides] = useState([])
    const [open, setOpen] = useState(false)
    const [add, setAddOpen] = useState(false)
    const [currentId, setCurrentId] = useState(0);
    const cancelButtonRef = useRef(null)
    const auth = getCookie("refresh_token")
    const [image_url, setImageUrl] = useState()
    const [clickListenerType, setClickListenerType] = useState("web_url")
    const [clickListener, setClickListener] = useState()
    React.useEffect(() => {
        axios.get(`${AppSettings.APIserver}/slider`).then((response) => {
	    setIsLoaded(true)
            setSlides(response.data.body)
        });
    }, []);
    function deleteSlide(id) {
        let newSlides = slides.filter(slide => slide.id !== id)
        setOpen(false)
    	const config = {
      		headers: { Authorization: `Bearer ${auth}` }
    	};
    	axios.delete(
      		`${AppSettings.APIserver}/slider/${id}`,
      		config
    	).then((response) => {
      		console.log(response)
		setSlides(newSlides)
    	}).catch((error) => {
      		console.log(error)
    	})
    }
    function saveNewSlide() {
      const config = {
      		headers: { Authorization: `Bearer ${auth}` }
      };
      const formData = new FormData();
      formData.append('image_url', image_url);
      formData.append('click_listener_type', clickListenerType);
      formData.append('click_listener', clickListener);
      
      axios.post(
      		`${AppSettings.APIserver}/slider`,
      		formData,
      		config
      ).then((response) => {
      		console.log(response)
      		setSlides([...slides, response.data.body])
      		setAddOpen(false)
      }).catch((error) => {
      		console.log(error)
      })
    }
    return (
        <React.Fragment>
			<Helmet>
				<title>سلاید ها</title>
			</Helmet>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block m:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">

                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                آیا مطمئن هستید که می خواهید این اسلاید را حذف کنید؟
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                        onClick={() => deleteSlide(currentId)}
                                    >
                                        حذف
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        انصراف
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <Transition.Root show={add} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">

                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                اضافه کردن اسلاید جدید
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">

                                        <div className="mt-2 w-full cursor-pointer p-4">
                                            <input id="file-upload" name="file-upload" type="file" onChange={(e) => {
                              			setImageUrl(e.target.files[0])
                            		    }} />                                            <div className='block py-4'>
                                                <select placeholder='لینک عکس' className='w-full py-2 pl-1 border border-gray float-right'  onChange={(e) => {setClickListenerType(e.target.value)}} >
                                                    <option web="web_url">لینک ویبسایت</option>
                                                    <option value="app_url">لینک موبایل</option>
                                                </select>
                                            </div>
                                            <br/>
                                            <div className='block py-2'>
                                                <input type="url" placeholder='لینک' className='w-full py-2 pl-1 border border-gray float-right'  onChange={(e) => {setClickListener(e.target.value)}}  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                        onClick={() => { setAddOpen(false); saveNewSlide() }}
                                    >
                                        ذخیره
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        onClick={() => setAddOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        انصراف
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="px-1 py-2 border border-gray-200">
                <div className='w-32 flex justify-evenly'>
                    <PlusIcon className='w-5 h-5 cursor-pointer' onClick={() => setAddOpen(true)} />
                </div>
            </div>
            <ul role="list" className="grid grid-cols-4 xl:grid-cols-6 gap-3 py-2 px-2 mt-4">
                {slides.map((slide) => (
		<div>
		    <li>
 		    <TrashIcon className="w-6 h-6 xl:h-8 xl:w-8 text-red-400 float-left" onClick={() => {
                        setCurrentId(slide.id);
                        setOpen(true);
                    }} />
		    </li>
		    <li>
		    	<p>{slide.id}</p>
		    </li>
		    <div className="my-2"/>
                    <li key={slide.id} className="relative cursor-pointer ">
                        <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                            <img src={AppSettings.APIserver + slide.image_url} alt="slider Image" className="object-cover pointer-events-none group-hover:opacity-75 h-full w-full " />
                        </div>
                    </li>
		</div>
                ))}
            </ul>
	    {isLoaded == false?<LoadingComponent /> :null}
        </React.Fragment>
    )
}
