import axios from "axios"
import AppSettings from "../app.settings.json"
import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import getCookie from "./getCookie"
import {Helmet} from "react-helmet"
export default function AddUsers() {
  const [name, setName] = useState()
  const [role, setRole] = useState(0)
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const auth = getCookie("refresh_token")
  const navigate = useNavigate()
  function handelSubmit() {
    if (password === confirmPassword) {
      const config = {
        headers: { Authorization: `Bearer ${auth}` }
      };
      let data = {
        name:name,
        password:password,
        is_admin:role
      }
      axios.post(
        `${AppSettings.APIserver}/register`,
        data,
        config
      ).then(
        navigate("/users", { replace: true })
      ).catch(error => {
        console.log(error)
      })

    } else {
      alert("رمز عبور با تکرار آن یکسان نیست")
      setPassword("")
      setConfirmPassword("")
    }
  }
	function handelKeyPress(event) {
    if (event.key == "Enter") {
      handelSubmit()
    }
  }

  return (
    <div>
	  <Helmet>
	  			<title>افزودن کاربر</title>
	  </Helmet>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-2
                 bg-white space-y-6 sm:p-6">
                <div className="px-6">
                  <h2>ایجاد کاربر جدید</h2>
                </div>
                <div className="px-4 py-2 bg-white sm:p-6">
                  <div className="grid gap-6 ">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                        نام کاربری
                      </label>
                      <input
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                        placeholder="نام کاربری"
                        type="username"
                        name="username"
                        id="username"
                        autoComplete="name"
                        required
                        className="mt-1 focus:ring-indigo-500  w-3/4 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                        نقش کاربری
                      </label>
                      <select
                        value={role}
                        onChange={(e) => { setRole(e.target.value) }}
                        placeholder="نقش کاربری"
                        type="role"
                        name="role"
                        required
                        id="role"
                        className="mt-1 focus:ring-indigo-500 w-3/4 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2"
                      >
                        <option value="0">کاربر</option>
                        <option value="1">مدیر</option>
                      </select>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        رمز عبور
                      </label>
                      <input
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }}
                        placeholder="رمز عبور"
                        type="password"
                        name="password"
                        id="password"
                        required
                        autoComplete="password"
                        className="mt-1 focus:ring-indigo-500 w-3/4 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        تکرار رمز عبور
                      </label>
                      <input
	  					onKeyPress={handelKeyPress}
                        value={confirmPassword}
                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                        placeholder="تکرار رمز عبور"
                        type="password"
                        name="confirmpassword"
                        id="confirmpassword"
                        autoComplete="confirmpassword"
                        required
                        className="mt-1 focus:ring-indigo-500 w-3/4 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="p-5">
                  <div className="flex justify-end">
                    <Link
                      to="/users"
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      لغو
                    </Link>
                    <button
                      onClick={handelSubmit}
                      className="mx-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      ثبت
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
