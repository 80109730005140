import axios from 'axios'
import { BookOpenIcon } from '@heroicons/react/outline'
import React, { Fragment, useState } from 'react'
import { Dialog, Transition, Menu ,Listbox} from '@headlessui/react'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom"
import AppSettings from '../app.settings.json'
import getCookie from '../pages/getCookie'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import Books from '../components/Books'
import SearchDropDown from '../components/SearchDropDown'
import {Helmet} from 'react-helmet'

export default function AddBookPage() {
  const auth = getCookie("refresh_token")
  const navigate = useNavigate()
  const [authorSelected, setAuthorSelected] = useState('')
  const [categorySelected, setCategorySelected] = useState('')
  const [categorys,setCategorys] = useState([])
  const [Authors, setAuthors] = useState([])
  const [uploading,setUploading] = useState(false)
  const [Data, setData] = useState({
	  image_url : "",
	  title : "",
	  publisher : "",
	  translator : "",
	  publish_year : "",
	  infinitive : "",
	  version : "",
	  about_book : "",
	  download_pdf_url : "",
	  download_ebook_url : "",
  })
    React.useEffect(() => {
    axios.get(`${AppSettings.APIserver}/category`).then((res)=>{
	setCategorys(res.data.body)
    })

    }, []);
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

function handelSubmit() {

  // I know it is not standard but I will Change it later   
  
   if (Data.title === "" || Data.download_pdf_url === "" || Data.publisher === "" || categorySelected === "") {
    alert("تمامی فیلد های نشانی شده را پر کنید")
   } else { 
      const config = {
         headers: { Authorization: `Bearer ${auth}` }
      }
	   setUploading(true)
     const form_data = new FormData()
     form_data.append('image_url',Data.image_url)
     form_data.append('title',Data.title)
     form_data.append('publisher',Data.publisher)
     form_data.append('translator',Data.translator)
     form_data.append('publisher_year',Data.publish_year)
     form_data.append('infinitive',Data.infinitive)
     form_data.append('type_version',Data.version)
     form_data.append('about_book',Data.about_book)
     form_data.append('content_book',"")
     form_data.append('pdf_download_url',Data.download_pdf_url)
     form_data.append('ebook_download_url',Data.download_ebook_url)
     form_data.append('category_id',categorySelected.id)
     form_data.append('author_id',authorSelected)
     form_data.append('date_of_added',Date.now())
     axios.post(
        `${AppSettings.APIserver}/book`,
        form_data,
        config,
		{timeout: 9000000}
       ).then((res => {
          console.log(res)
          if (res.data.code === "author_not_found") {
            alert("نویسنده یافت نشد. لطفاابتدا نویسنده را ثبت کنید")
          } else if (res.data.code === "incorrect_image_format") {
            alert("فرمت عکس باید jpg, png, jpeg باشد")
          } else if (res.data.code === "incorrect_pdf_format") {
            alert("فرمت فایل باید pdf باشد")
          } else if (res.data.code === "incorrect_ebook_format") {
            alert("فرمت ebook باید .epub باشد")
          }
          navigate("/",{replace:true})
       })).catch(error => {
	    setUploading(false)
		alert("خطا در ارسال اطلاعات")
        console.log(error)
       })
    }
}

function handelSearch(e){
	    axios.post(`${AppSettings.APIserver}/search`,{
                'type': "author",
                'keyword': e,
                'authors': [],
                'categories': [],
                'order_by':1
            }).then((res) => {
		setAuthors(res.data.author_data)
            }).catch((e) => {
                console.log(e)
            })
  }

  return (
    <>
	<Helmet>
	  		<title>افزودن کتاب</title>
	</Helmet>
      <div>
	  <div className={uploading?"w-full bg-white":'hidden'} style={{height:'100vh',position:'fixed',zIndex:'25',margin:'-40px -50px'}} >
		  <div className="flex justify-center items-center h-full">
	  			<h1>در حال اضافه کردن ...</h1>
		  </div>
	  </div>
        <div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div>
             
                  <div className="px-4 bg-white">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6">
                        <label htmlFor="BooknName" className="block text-sm font-medium text-gray-700">
                          * نام کتاب 
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="BooknName"
                          autoComplete="name"
                          onChange={(e) => {setData({...Data,title: e.target.value}) }}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                        />
                      </div>
		     
                        <div className="sm:col-span-3 ">
	  			<SearchDropDown search={e=>{handelSearch(e)}} Authors={Authors} setSelected={e=> { setAuthorSelected(e)}} />
                        </div>
                        <div className="sm:col-span-3 w-full">
                            <Listbox value={categorySelected} onChange={setCategorySelected}>
                                {({ open }) => (
                                    <>
                                        <Listbox.Label className="block text-sm font-medium text-gray-700">* دسته بندی ها</Listbox.Label>
                                        <div className="mt-1 relative">
                                            <Listbox.Button className="relative w-full bg-white border px-5 border-gray-300 rounded-md shadow-sm py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                <span className="flex items-center">
                                                    <img src={AppSettings.APIserver+categorySelected.image_url} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                                                    <span className="mr-2 block truncate">{categorySelected.name}</span>
                                                </span>
                                                <span className="absolute inset-y-0 right-0 flex items-center pointer-events-none">
                                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </span>
                                            </Listbox.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                    {categorys.map((category) => (
                                                        <Listbox.Option
                                                            key={category.id}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                                    'cursor-default select-none relative py-2'
                                                                )
                                                            }
                                                            value={category}
                                                        >
                                                            {({ categorySelected, active }) => (
                                                                <>
                                                                    <div className="flex items-center">
                                                                        <img src={AppSettings.APIserver+category.image_url} alt="" className="flex-shrink-0 h-6 w-6 rounded-full mr-4" />
                                                                        <span
                                                                            className={classNames(categorySelected ? 'font-semibold' : 'font-normal', ' block truncate mr-3')}
                                                                        >
                                                                            {category.name}
                                                                        </span>
                                                                    </div>

                                                                    {categorySelected ? (
                                                                        <span
                                                                            className={classNames(
                                                                                active ? 'text-white' : 'text-indigo-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pl-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </>
                                )}
                            </Listbox>
                        </div>
                      <div className="col-span-2">
                        <label htmlFor="BooknName" className="block text-sm font-medium text-gray-700">
                           * ناشر
	  		        </label>
                        <input
                          type="text"
                          name="publisher"
                          id="BooknName"
                          autoComplete="name"
                          onChange={(e) => {setData({...Data,publisher: e.target.value}) }}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                        />
                      </div>
		      <div className="col-span-2">
                        <label htmlFor="TypeVersion" className="block text-sm font-medium text-gray-700">
                          مترجم
                        </label>
                        <input
                          type="Translator"
                          name="Translator"
                          id="Translator"
                          autoComplete="Translator"
                          onChange={(e) => {setData({...Data,translator: e.target.value}) }}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                        />
                      </div>
		      <div className="col-span-2">
                        <label htmlFor="TypeVersion" className="block text-sm font-medium text-gray-700">
                          سال انتشار
                        </label>
                        <input
                          name="PublisYear"
                          id="PublisYear"
                          autoComplete="PublisYear"
                          onChange={(e) => {setData({...Data,publish_year: e.target.value}) }}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                        />
                      </div>
		      <div className="col-span-3">
                        <label htmlFor="TypeVersion" className="block text-sm font-medium text-gray-700">
        		  منبع
                        </label>
                        <input
                          type="text"
                          name="Infinitive"
                          id="Infinitive"
                          autoComplete="Infinitive"
                          onChange={(e) => {setData({...Data,infinitive: e.target.value}) }}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                        />
                      </div>
                      <div className="col-span-3">
                        <label htmlFor="TypeVersion" className="block text-sm font-medium text-gray-700">
                          نسخه کتاب
                        </label>
                        <input
                          type="TypeVersion"
                          name="TypeVersion"
                          id="TypeVersion"
                          autoComplete="TypeVersion"
                          onChange={(e) => {setData({...Data,version: e.target.value}) }}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 "
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6 ">
                        <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 mb-1">
                          درباره کتاب
                        </label>
                        <textarea
                          id="about"
                          name="about"
                          className="max-w-2xl shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md resize p-2 h-24"
                          defaultValue={''}
                          onChange={(e) => {setData({...Data,about_book: e.target.value}) }}
                        />
                      </div>
					  {/*
                      <div className="col-span-6 sm:col-span-6 ">
                        <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 mb-1">
                         متن کتاب
	  		</label>
                        <textarea
                          id="about"
                          name="about"
                          className="max-w-2xl shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md resize  p-2 h-28"
                          defaultValue={''}
                          onChange={(e) => {setData({...Data,content_book: e.target.value}) }}
                        />
                      </div>
					*/}
                    </div>
                  </div>
		<div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-2 bg-white space-y-6 ">
                  <div className="px-4">
                    <label className="block text-sm font-medium text-gray-700">عکس کتاب</label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                          >
                            <input id="file-upload" name="file-upload" type="file"  
                            onChange={(e) => {setData({...Data,image_url: e.target.files[0]}) }} 
                            accept="image/jpeg, image/png, image/jpg"
                                    />
                          </label>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, Jpeg</p>
                      </div>
                    </div>
                  </div>
                  <div className="px-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">* کتاب (PDF)</label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-5 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <div className="flex justify-center">
                          <BookOpenIcon className="w-20" />
                        </div>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                          >
                            <input type="file"  
                             	onChange={(e) => {
                                setData({...Data,download_pdf_url: e.target.files[0]}) 
                              }}
                              accept="application/pdf"
	  		                    />
                          </label>
                        </div>
                        <p className="text-xs text-gray-500">کتاب انتخاب شده باید (PDF) باشد</p>
                      </div>
                    </div>
	  	    <div className="my-8"></div>
		    <label className="block text-sm font-medium text-gray-700 mb-2">کتاب (EBook)</label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-5 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <div className="flex justify-center">
                          <BookOpenIcon className="w-20" />
                        </div>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                          >
                            <input type="file"                              	
                              onChange={(e) => {setData({...Data,download_ebook_url: e.target.files[0]}) }}
                              accept="application/epub"
	  		            />
                          </label>
                        </div>
                        <p className="text-xs text-gray-500">کتاب انتخاب شده باید (ebook) باشد</p>
                      </div>
                    </div>
                    <div className='pb-4 pt-2'>
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 float-right"
                        onClick={handelSubmit}
                      >
                        اضافه کردن
                      </button>
                    </div>
                    <br/>
                  </div>
                </div>
              </div>
            </div>
	</div>
      </div>
    </div>
    </>
  )
}
