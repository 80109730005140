import { useParams } from "react-router-dom"
import axios from 'axios'
import React, { useState } from 'react'
import AppSettings from '../app.settings.json'
import {Helmet} from 'react-helmet'

export default function CategoryDetails() {
  const { id } = useParams()
  const [CategoryDetail, setCategoryDetail] = useState([])
  React.useEffect(() => {
    axios.get(`${AppSettings.APIserver}/category/${id}`).then((response) => {
      var timestamp = response.data.body.date_of_started
      var date = new Date(parseInt(timestamp));
      date = date.getDate() +
      "/" + (date.getMonth() + 1) +
      "/" + date.getFullYear();
      response.data.body.date_of_started = date;
      setCategoryDetail(response.data.body)
    });
  }, []);
  return (
	<>
	<Helmet>
			<title>{CategoryDetail.name}</title>
	</Helmet>
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">توضیحات دسته بندی</h3>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 w-full justify-center pr-4" style={{display:'flex'}}>
            <img
              className="inline-block h-40 w-40 rounded-md cursor-pointer"
	      src={AppSettings.APIserver + CategoryDetail.image_url}
            />
	  </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">نام نویسنده</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{CategoryDetail.name}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">تعداد کتاب ها</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{CategoryDetail.number_of_books}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">تاریخ ایجاد</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{CategoryDetail.date_of_started}</dd>
          </div>
        </dl>
      </div>
    </div>
	</>
  )
}
